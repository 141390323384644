.listItem,
.listItemLink {
    display: block;
}

.listItem {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
}

.listItem:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.listItemBtn {
    color: #fff;
    text-decoration: none;
    display: flex;
    padding: 5px 0 5px 30px;
    transition: all 0.2s linear;
    position: relative;
    min-height: 44px;
    align-items: center;
    width: 100%;
    outline: none;
    font-family: inherit;
    text-align: left;
}

@media (min-width: 1024px) {
    .listItemBtn {
        min-height: 40px;
    }
}

.listItemBtnActive {
    composes: listItemBtn;
    background: #024D6C;
    width: calc(100% + 30px);
}

.listItemKeyLine {
    width: 18px;
    height: 5px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2em;
    flex: none;
}

.listItemKeyIcon {
    width: 18px;
    font-size: 18px;
    margin-right: 10px;
    display: block;
    flex: none;
    color: #009EBD;
}

.listItemLabel {
    font-size: 17px;
    margin-right: 10px;
}

@media (min-width: 1024px) {
    .listItemLabel {
        font-size: 14px;
    }
}

.listItemCaret {
    margin: 0 15px 0 auto;
    flex: none;
    color: #fff;
    opacity: 0.3;
    font-size: 13px;
}