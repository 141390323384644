.key {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 200px;
    transition: all 300ms ease-out;
    pointer-events: none;
}

@media (min-width: 1024px) {
    .key {
        top: auto;
        bottom: 45px;
        left: 60px;
        width: auto;
        height: auto;
    }
}

.isInset {
    transform: translateX(320px);
}

.isOpen .list {
    visibility: visible;
    opacity: 1;
}

.list {
    visibility: hidden;
    opacity: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    background: #fff;
    font-size: 13px;
    width: 100%;
    padding: 10px 20px;
    transition: all 300ms ease-out;
    pointer-events: all;
}

@media (min-width: 1024px) {
    .list {
        visibility: visible;
        opacity: 1;
        flex-direction: row;
        border-radius: 25px;
        align-items: center;
        box-shadow: 0 3px 14px 0 rgba(0,0,0,0.1);
        width: auto;
        height: 50px;
        padding: 0 25px;
    }
}

.keyItem {
    display: flex;
    align-items: center;
}

@media (max-width: 1023px) {
    .keyItem + .keyItem {
        border-top: 1px solid #EFEFEF;
        padding-top: 5px;
        margin-top: 5px;
    }
}

@media (min-width: 1024px) {
    .keyItem + .keyItem {
        border-left: 1px solid #EFEFEF;
        padding-left: 10px;
        margin-left: 10px;
    }
}

.keyColor {
    display: block;
    width: 18px;
    height: 5px;
    border-radius: 2.5px;
    margin-right: 10px;
}

.button {
    background: #009EBD;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    text-align: center;
    justify-content: center;
    font-family: 'bebas_neue', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    text-transform: uppercase;
    outline: none;
    pointer-events: all;
}

.buttonIcon {
    position: relative;
    width: 8px;
    height: 2px;
    margin-left: 5px;
}

.buttonIcon::before,
.buttonIcon::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .15s linear, opacity .15s linear;
}

.buttonIcon::after {
    transform: translateY(-50%) rotate(90deg);
}

.button[aria-expanded="true"] .buttonIcon::after  {
    transform: translateY(-50%) rotate(0);
}