.root {
    position: absolute;
    right: 25px;
    bottom: 15px;
    z-index: 1;
    width: 50px;
}

.control {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
    margin-bottom: 20px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    color: #fff;
    border: 5px solid transparent;
}

.control:focus {
    outline: none;
}

.controlIcon {
    font-size: 24px;
    pointer-events: none;
}

.controlInfo {
    composes: control;
    visibility: hidden;
    margin-top: -70px;
    opacity: 0;
    background: #009EBD;
}

.controlInfoActive {
    composes: control;
    composes: controlInfo;
    visibility: visible;
    margin-top: 0;
    opacity: 1;
}

.controlSettings {
    composes: control;
    background: #B72055;
}

.controlDraw {
    composes: control;
    background: #4897E8;
}

.controlDrawActive {
    composes: control;
    background: #4897E8;
    border: 5px solid #9CCDFF;
    height: 60px;
    width: 60px;
    margin-top: -5px;
    margin-left: -5px;
}

.controlTrash {
    composes: control;
    background: #B72055;
}


@media (min-width: 1024px) {
    .root {
        bottom: auto;
        top: 50px;
        right: 50px;
    }
}