.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 120px;
    background: #003E59;
    color: #fff;
    padding: 0 20px;
    display: flex;
}

.plannerHeader {
    composes: header;
    align-items: center;
    padding: 0 20px 0 15px;
    background: #00212F;
}

.routesHeader {
    composes: header;
    flex-direction: column;
    justify-content: flex-end;
}


@media (min-width: 1024px) {
    .root {
        display: none;
    }
}

.title {
    margin: 0;
    padding: 10px 0;
    display: flex;
    align-items: flex-end;
}

.controls {
    display: flex;
    padding: 0 25px;
    margin: 0 -25px;
    height: 45px;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    justify-content: space-between;
}

.control {
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
}

.controlIcon {
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
}