.root {
    position: relative;
    height: 100%;
    overflow: auto;
}

@media (max-width: 1023px) {
    .root::before {
        content: '';
        display: block;
        height: calc(100% - 100px);
    }
}

.body {
    position: fixed;
    top: 120px;
    left: 0;
    height: calc(100% - 120px);
    width: 100%;
    transition: all 300ms ease-out;
}

.isMini {
    height: calc(100% - 100px - 120px);
}

.isDrawMode :global(.mapboxgl-canvas-container) {
    cursor: crosshair;
}

.isDrawMode :global(.mouse-pointer) :global(.mapboxgl-canvas-container) {
    cursor: pointer;
}

@media (min-width: 1024px) {
    .body {
        position: static;
        height: 100%;
    }
}


.logo {
    position: absolute;
    left: 50px;
    top: 40px;
    width: 96px;
    display: none;
    z-index: 1;
    cursor: pointer;
}

@media (min-width: 1024px) {
    .logo {
        display: block;
    }    
}