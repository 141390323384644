.root {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 100;
    transition: all 300ms ease-out;
    transform: translateX(-100%);
}

.isActive {
    composes: root;
    transform: translateX(0);
}

@media (max-width: 1023px) {
    .root {
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .root {
        width: 320px;
    }
}


.inner {
    composes: customScrollbarDark from '../../shared.css';
    height: 100%;
    overflow: auto;
    padding: 0 20px 30px;
    position: relative;
    z-index: 100;
    background: #002B3E;
    color: #fff;
    will-change: transform;
}

.brand {
    background: #003e59;
    margin: 0 -20px 25px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand img {
    width: 110px;
    cursor: pointer;
}


.btnToggle {
    position: absolute;
    left: 100%;
    bottom: 45px;
    border: 0;
    background: #009EBD;
    height: 50px;
    width: 40px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 0 10px 0 0;
}

.btnToggle:focus {
    outline: none;
    background: #0091AE;
}

@media (max-width: 1023px) {
    .btnToggle {
        display: none;
    }
}

.credits {
    font-size: 12px;
    color: #009EBD;
    margin: 40px 0 0;
}

.credits img {
    margin-right: 20px;
}