.root {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  overflow-x: hidden;
}

.container {
  height: 100%;
}


.header {
  text-align: center;
}

.headerTitle {
  font-size: 28px;
  margin-bottom: 10px;
}

.headerDesc {
  font-size: 12px;
  margin-bottom: 2em;
}

.tabs {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 -20px 20px;
  padding: 0 5px;
  border-bottom: 2px solid #024D6C;
}

.tab {
  flex: 1 1 auto;
  margin: 0 5px;
}

.tabLink {
  display: block;
  padding: 10px 15px;
  background: #00212F;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #fff;
  font-family: 'bebas_neue', sans-serif;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  color: #225870;
  transition: all 0.2s linear;
  position: relative;
  overflow: hidden;
}

.tabLink[aria-current] {
  background: #003E59;
  color: #fff;
}

.tabIcon {
  display: block;
  font-size: 38px;
  margin: 0 auto 10px;
  color: #009EBD;
}

.tabLink[aria-current] .tabIcon {
  color: #4ED2BB;
}

.tabBadge {
  background: #85719E;
  color: #fff;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  transform: rotate(-45deg) translate(-50%, 15px);
  width: 100%;
  transform-origin: 0 0;
  padding: 2px 0;
}