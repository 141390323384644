.shared_button__3pRYd {
    background: #B72055;
    color: #fff;
    font-weight: 500;
    border: 0;
    font-size: 17px;
    padding: 15px 30px;
    box-shadow: 0 3px 7px rgba(0,0,0,0.2);
    border-radius: 3px;
}

.shared_buttonBlock__34U8X {
    width: 100%;
}


/* Custom scrollbar */

.shared_customScrollbar__3JHCY {
    height: 100%;
    overflow: auto;
    padding: 0 30px;
}

.shared_customScrollbar__3JHCY::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: 0 0;
}

.shared_customScrollbar__3JHCY::-webkit-scrollbar:hover {
    background: 0 0;
}

.shared_customScrollbar__3JHCY::-webkit-scrollbar-track {
    background: 0 0;
}

.shared_customScrollbar__3JHCY::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.25);
    border-color: transparent;
    width: 6px;
    border-radius: 3px
}

.shared_customScrollbar__3JHCY::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,.35)
}

.shared_customScrollbar__3JHCY:-webkit-scrollbar-track:hover {
    background: 0 0
}


/* Custom scrollbar dark */

.shared_customScrollbarDark__3D2pS {
}

.shared_customScrollbarDark__3D2pS::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,.3)
}

.shared_customScrollbar__3JHCY::-webkit-scrollbar-thumb:hover {
    background: rgba(255,255,255,.4)
}


/* Custom scrollbar light */


/* Splash screen */

.SplashScreen_splash__3b0VA {
  height: 100%;
  overflow: auto;
  background: #003e59 0 100% / contain no-repeat;
  color: #fff;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-size: contain;
}

@media (min-width: 1024px) {
  .SplashScreen_splash__3b0VA {
    background-size: contain;
  }
}

.SplashScreen_body__d7i4J {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  max-width: 375px;
  margin: 0 auto;
  padding: 40px 20px;
}

.SplashScreen_logo__3YQMA {
  width: 148px;
  margin: 0 0 20px;
}

@media (min-width: 1024px) {
  .SplashScreen_logo__3YQMA {
    width: 264px;
  }
}

.SplashScreen_title__1HzPQ {
  font-size: 51px;
  margin-bottom: 0.25em;
  max-width: 100%;
}

.SplashScreen_desc__2e81D {
  margin-bottom: 0;
  max-width: 100%;
}

.SplashScreen_btn__GS36x {
  margin: 1em 0 0;
  text-decoration: none;
}

.SplashScreen_copy__30DYX {
  font-size: 12px;
  opacity: 0.3;
  margin-top: auto;
  padding-bottom: 40px;
}

.SplashScreen_newFeatures__35TrR {
  border-top: 2px solid #024D6C;
  padding-top: 20px;
  margin-top: 20px;
}

.SplashScreen_bannerHeading__2kDf2 {
  margin: 0 0 .5em;
}

.SplashScreen_featureHeading__2HLHn {
  text-transform: none;
  font-family: inherit;
  margin: 0 0 .5em;
}
.Icon_icon__ABe8d {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}
.Navigation_root__2f8uk {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 100;
    transition: all 300ms ease-out;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.Navigation_isActive__1sAS_ {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

@media (max-width: 1023px) {
    .Navigation_root__2f8uk {
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .Navigation_root__2f8uk {
        width: 320px;
    }
}


.Navigation_inner__fijoD {
    height: 100%;
    overflow: auto;
    padding: 0 20px 30px;
    position: relative;
    z-index: 100;
    background: #002B3E;
    color: #fff;
    will-change: transform;
}

.Navigation_brand__2aGQ8 {
    background: #003e59;
    margin: 0 -20px 25px;
    height: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Navigation_brand__2aGQ8 img {
    width: 110px;
    cursor: pointer;
}


.Navigation_btnToggle__3EtYh {
    position: absolute;
    left: 100%;
    bottom: 45px;
    border: 0;
    background: #009EBD;
    height: 50px;
    width: 40px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 0 10px 0 0;
}

.Navigation_btnToggle__3EtYh:focus {
    outline: none;
    background: #0091AE;
}

@media (max-width: 1023px) {
    .Navigation_btnToggle__3EtYh {
        display: none;
    }
}

.Navigation_credits__3O-y7 {
    font-size: 12px;
    color: #009EBD;
    margin: 40px 0 0;
}

.Navigation_credits__3O-y7 img {
    margin-right: 20px;
}
.ListItem_listItem__2nzrE,
.ListItem_listItemLink__1_kwE {
    display: block;
}

.ListItem_listItem__2nzrE {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: -webkit-flex;
    display: flex;
}

.ListItem_listItem__2nzrE:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ListItem_listItemBtn__3MJQJ {
    color: #fff;
    text-decoration: none;
    display: -webkit-flex;
    display: flex;
    padding: 5px 0 5px 30px;
    transition: all 0.2s linear;
    position: relative;
    min-height: 44px;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    outline: none;
    font-family: inherit;
    text-align: left;
}

@media (min-width: 1024px) {
    .ListItem_listItemBtn__3MJQJ {
        min-height: 40px;
    }
}

.ListItem_listItemBtnActive__2_N8M {
    background: #024D6C;
    width: calc(100% + 30px);
}

.ListItem_listItemKeyLine__3UwKx {
    width: 18px;
    height: 5px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2em;
    -webkit-flex: none;
            flex: none;
}

.ListItem_listItemKeyIcon__1qN05 {
    width: 18px;
    font-size: 18px;
    margin-right: 10px;
    display: block;
    -webkit-flex: none;
            flex: none;
    color: #009EBD;
}

.ListItem_listItemLabel__3RY9Y {
    font-size: 17px;
    margin-right: 10px;
}

@media (min-width: 1024px) {
    .ListItem_listItemLabel__3RY9Y {
        font-size: 14px;
    }
}

.ListItem_listItemCaret__2kNEX {
    margin: 0 15px 0 auto;
    -webkit-flex: none;
            flex: none;
    color: #fff;
    opacity: 0.3;
    font-size: 13px;
}
.CycleRoutes_catHeader__1YviM {
    color: #009EBD;
    font-size: 24px;
}

@media (min-width: 1024px) {
    .CycleRoutes_catHeader__1YviM {
        font-size: 20px;
    }
}


.CycleRoutes_list__cR38z {
    list-style: none;
    padding: 0;
    margin: 0 -20px 25px;
}

@media (min-width: 1024px) {
    .CycleRoutes_list__cR38z {
        margin-bottom: 40px;
    }
}
.Geocoder_geocoder__84ZIx {
    position: relative;
}


.Geocoder_input__1OL7Z {
    width: 100%;
    height: 25px;
    background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-size: 16px;
    font-family: inherit;
    color: #fff;
    padding: 0;
}

.Geocoder_input__1OL7Z:focus {
    outline: none;
}

.Geocoder_input__1OL7Z::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .5);
}

.Geocoder_input__1OL7Z:-ms-input-placeholder {
    color: rgba(255, 255, 255, .5);
}

.Geocoder_input__1OL7Z::-ms-input-placeholder {
    color: rgba(255, 255, 255, .5);
}

.Geocoder_input__1OL7Z::placeholder {
    color: rgba(255, 255, 255, .5);
}


.Geocoder_inputLocate__irVhL {
    padding-right: 30px;
}


.Geocoder_locateBtn__2fvRd {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    color: #009EBD;
}


.Geocoder_autocomplete__Uoeew {
    position: absolute;
    top: 100%;
    z-index: 999;
    background: #003E59;
    color: #fff;
    list-style: none;
    padding: 0;
    box-shadow: 0 3px 14px 0 rgba(0,0,0,0.1);
}

.Geocoder_autocompleteItem__3-bBV {
    text-align: left;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s linear;
}

.Geocoder_autocompleteItem__3-bBV:hover {
    background: rgba(255,255,255,0.1);
}

.Geocoder_autocompleteItem__3-bBV[data-selected="true"] {
    background: #009EBD;
}
.RouteForm_container__KBjt3 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

@media (min-width: 1024px) {
    .RouteForm_container__KBjt3 {
        margin-bottom: 30px;
    }
}

.RouteForm_form__1yv7F {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.RouteForm_fields__bI9jo {
    position: relative;
    padding-left: 30px;
}

.RouteForm_backBtn__rRzQY {
    font-size: 16px;
    margin: 5px 10px 0 0;
}

.RouteForm_graphic__1O2Gm {
    position: absolute;
    left: 0;
    top: .35em;
}

.RouteForm_label__1u8ee {
    font-size: 13px;
    color: #009EBD;
    line-height: 1;
}

.RouteForm_button__1Q0mq {
    background: #B72055;
    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.20);
    border-radius: 3px;
    width: 100%;
    height: 50px;
    font-size: 17px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 500;
    margin: 20px 0 0;
}
.Information_table__1ZNdk {
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

.Information_row__1gx_o:nth-of-type(odd) {
    background: rgba(34, 88, 112, .25);
}

.Information_row__1gx_o th,
.Information_row__1gx_o td {
    padding: 10px 5px;
}

.Information_row__1gx_o th {
    font-weight: normal;
}

.Information_row__1gx_o td {
    font-weight: bold;
    text-align: right;
}

.Information_small__1Kdwr {
    font-size: 12px;
    color: rgba(255,255,255,.65);
    font-weight: 500;
    margin: 0;
}
.Directions_plans__1nOtL {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    padding: 0;
    margin: 0 -20px 20px;
    padding: 0 5px;
    border-bottom: 2px solid #024D6C;
}

.Directions_plan__1JyUE {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    margin: 0 5px;
}

.Directions_planRadio__1jURI {
    position: absolute;
    opacity: 0;
}

.Directions_planLabel__1jkH9 {
    display: block;
    padding: 10px 15px;
    background: #00212F;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #fff;
    font-family: 'bebas_neue', sans-serif;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    color: #225870;
    transition: all 0.2s linear;
}

.Directions_planRadio__1jURI:checked + .Directions_planLabel__1jkH9 {
    background: #003E59;
    color: #fff;
}

.Directions_timeDistance__DgJE3 {
    font-size: 18px;
    color: #009EBD;
}

.Directions_timeDistance__DgJE3 time {
    color: #FFFFFF;
    margin-right: 5px;
}

.Directions_timeDistance__DgJE3 strong {
    font-family: 'bebas_neue', sans-serif;
    font-size: 28px;
    font-weight: 700;
}


/* Accordion */


@-webkit-keyframes Directions_fadein__2R69B {
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
}


@keyframes Directions_fadein__2R69B {
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
}
  

.Directions_accordionItem__1ROru:last-child {
    border-bottom: 1px solid rgba(255,255,255,0.1);
}

.Directions_accordionButton__1uizc {
    outline: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border-top: 1px solid rgba(255,255,255,0.1);
    padding: 15px 0;
    cursor: pointer;
    font-family: 'bebas_neue', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #009EBD;
}


.Directions_accordionToggle__20lCA {
    position: relative;
    font-family: 'quicksand', sans-serif;
    font-size: 14px;
    color: #225870;
    letter-spacing: 0.5px;
    text-align: right;
    padding-right: 15px;
}

.Directions_accordionToggle__20lCA::before,
.Directions_accordionToggle__20lCA::after {
    content: '';
    display: block;
    width: 8px;
    height: 2px;
    background: #009EBD;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: opacity .15s linear, -webkit-transform .15s linear;
    transition: transform .15s linear, opacity .15s linear;
    transition: transform .15s linear, opacity .15s linear, -webkit-transform .15s linear;
}

.Directions_accordionToggle__20lCA::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
            transform: translateY(-50%) rotate(90deg);
}

.Directions_accordionToggleExpanded__z6zHz {
}

.Directions_accordionToggleExpanded__z6zHz::after {
    -webkit-transform: translateY(-50%) rotate(0);
            transform: translateY(-50%) rotate(0);
}

.Directions_accordionPanel__HwBnG {
    -webkit-animation: Directions_fadein__2R69B 0.35s ease-in;
            animation: Directions_fadein__2R69B 0.35s ease-in;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 0 0 20px;
}


/* Directions */


.Directions_directions__2G9i3 {
    list-style: none;
    padding: 0;
}

.Directions_direction__QYu4m {
    display: -webkit-flex;
    display: flex;
    padding: 13px 12px;
    font-size: 14px;
}

.Directions_direction__QYu4m:nth-child(odd) {
    background: rgba(34,88,112,.25);
}

.Directions_direction__QYu4m:last-child .Directions_directionIcon__1F2I_ {
    color: #B72055;
}

.Directions_directionIcon__1F2I_ {
    font-size: 18px;
    margin-right: 10px;
}

.Directions_directionBody__3xGmw small {
    font-size: 12px;
    color: rgba(255, 255, 255, .5);
}

.Directions_directionBody__3xGmw small::before {
    content: '';
    display: inline-block;
    height: 5px;
    width: 18px;
    border-radius: 2.5px;
    background: #4897e8;
    margin-right: 10px;
    vertical-align: middle;
}


.Directions_directionBody__3xGmw small[data-provision="Footpath"]::before,
.Directions_directionBody__3xGmw small[data-provision="Bridleway"]::before,
.Directions_directionBody__3xGmw small[data-provision="Track"]::before,
.Directions_directionBody__3xGmw small[data-provision="Steps"]::before,
.Directions_directionBody__3xGmw small[data-provision="Pedestrianized area"]::before {
    background: #efc82f;
}

.Directions_directionBody__3xGmw small[data-provision="Cycle path"]::before,
.Directions_directionBody__3xGmw small[data-provision="Path"]::before {
    background: #2A3BBD;
}
.RoutePlanner_intro__4ljMd {
    font-size: 14px;
}
.Header_header__1LFQ6 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 120px;
    background: #003E59;
    color: #fff;
    padding: 0 20px;
    display: -webkit-flex;
    display: flex;
}

.Header_plannerHeader__2I-V_ {
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px 0 15px;
    background: #00212F;
}

.Header_routesHeader__37bWs {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}


@media (min-width: 1024px) {
    .Header_root__1cyT0 {
        display: none;
    }
}

.Header_title__3Dv-K {
    margin: 0;
    padding: 10px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.Header_controls__AhTt_ {
    display: -webkit-flex;
    display: flex;
    padding: 0 25px;
    margin: 0 -25px;
    height: 45px;
    -webkit-align-items: center;
            align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Header_control__3pLjD {
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
}

.Header_controlIcon__1ND7F {
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
}
.Panel_panel__M7UFu {
    background: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Panel_panelPositioned__2SsTQ {
    position: fixed;
    z-index: 3;
    overflow: auto;
}

.Panel_right__2iBB2 {
    right: 0;
    top: 0;
    width: 320px;
    height: 100%;
}

.Panel_bottom__2j5wb {
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc((100% - 130px) / 2);
}

.Panel_static__185ze {
    position: relative;
    z-index: 2;
}

.Panel_dark__3m2aZ {
    background: #002B3E;
    color: #fff;
}

.Panel_bottom__2j5wb .Panel_titleBar__2F45-,
.Panel_static__185ze .Panel_titleBar__2F45- {
    height: auto;
}

.Panel_titleBar__2F45- {
    display: -webkit-flex;
    display: flex;
    padding: 20px 25px 15px 0;
    margin-left: 25px;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    height: 90px;
    border-bottom: 1px solid #EFEFEF;
}

.Panel_title__2eKYI {
    margin: 0;
    color: #009EBD;
}

.Panel_intro__3Q7S2 {
    font-size: 14px;
    margin: 0 0 30px;
    font-weight: bold;
}

.Panel_closeBtn__1dYmF {
    margin: 0 0 -4px auto;
    font-size: 20px;
    color: #B4B4B3;
}

.Panel_body__3ASAL {
    padding: 15px 20px;
    -webkit-flex: 1 1;
            flex: 1 1;
}

@media (min-width: 1024px) {
    .Panel_body__3ASAL {
        padding: 15px 25px;
    }
}
.Info_title__1YIEs {
    color: #003E59;
    margin: 0 0 0.5em;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.Info_body__1twg9 {

}
.Settings_list__3JZ5N {
    list-style: none;
    padding: 0;
    margin-top: -15px;
}

.Settings_listItem__3d0_z {
    border-bottom: 1px solid #EFEFEF;
    margin-right: -25px;
    padding-right: 25px;
}

.Settings_toggle__Bzm52 {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    padding: 10px 0;
    font-family: inherit;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.Settings_toggle__Bzm52:focus {
    outline: none;
}

.Settings_toggleSwitch__27HTE {
    display: block;
    width: 36px;
    height: 20px;
    background: #EFEFEF;
    position: relative;
    border-radius: 10px;
    margin-right: 15px;
    -webkit-flex: none;
            flex: none;
}

.Settings_toggleSwitch__27HTE::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 8px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all 0.15s linear;
}

.Settings_toggleSwitchActive__3Exku {
}

.Settings_toggleSwitchActive__3Exku::before {
    background: #B72055;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}

.Settings_toggleSwitchDisabled__3QnT0 {
    visibility: hidden;
}

.Settings_toggleLabel__4BKCj {
    margin: 0 20px 0 0;
    font-size: 14px;
    text-align: left;
}

.Settings_toggleLabelLg__3e_3H {
    font-size: 16px;
    margin-top: -0.15em;
}

.Settings_toggleIcon__BDvsd {
    margin: 0 0 0 auto;
    width: 20px;
}

.Settings_description__3Cmvv {
    font-size: 12px;
    padding-left: 51px;
    padding-right: 40px;
}

.Settings_title__3WMGJ {
    padding: 0 0 15px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #EFEFEF;
    color: #009EBD;
}
.Map_map__3eB4r {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

/* Map styling */

.mapboxgl-canvas {
    outline: none;
}


.mapboxgl-popup-content {
    width: 200px;
    background: #003E59;
    color: #fff;
    padding: 10px;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25);
}

.mapboxgl-popup-content h3 {
    color: #fff;
    font-family: 'quicksand';
    font-weight: 500;
    margin: 0;
    font-size: 12px;
}

.mapboxgl-popup-content small {
    color: #fff;
    font-family: 'quicksand';
    font-weight: 500;
    opacity: 0.5;
    font-size: 12px;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: #003E59;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: #003E59;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: #003E59;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: #003E59;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: #003E59;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    border-top-color: #003E59;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: #003E59;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    border-bottom-color: #003E59;
}
.MapKey_key__3te05 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 200px;
    transition: all 300ms ease-out;
    pointer-events: none;
}

@media (min-width: 1024px) {
    .MapKey_key__3te05 {
        top: auto;
        bottom: 45px;
        left: 60px;
        width: auto;
        height: auto;
    }
}

.MapKey_isInset__3mnL0 {
    -webkit-transform: translateX(320px);
            transform: translateX(320px);
}

.MapKey_isOpen__gYb5l .MapKey_list__LmQ2e {
    visibility: visible;
    opacity: 1;
}

.MapKey_list__LmQ2e {
    visibility: hidden;
    opacity: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: #fff;
    font-size: 13px;
    width: 100%;
    padding: 10px 20px;
    transition: all 300ms ease-out;
    pointer-events: all;
}

@media (min-width: 1024px) {
    .MapKey_list__LmQ2e {
        visibility: visible;
        opacity: 1;
        -webkit-flex-direction: row;
                flex-direction: row;
        border-radius: 25px;
        -webkit-align-items: center;
                align-items: center;
        box-shadow: 0 3px 14px 0 rgba(0,0,0,0.1);
        width: auto;
        height: 50px;
        padding: 0 25px;
    }
}

.MapKey_keyItem__33DHx {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

@media (max-width: 1023px) {
    .MapKey_keyItem__33DHx + .MapKey_keyItem__33DHx {
        border-top: 1px solid #EFEFEF;
        padding-top: 5px;
        margin-top: 5px;
    }
}

@media (min-width: 1024px) {
    .MapKey_keyItem__33DHx + .MapKey_keyItem__33DHx {
        border-left: 1px solid #EFEFEF;
        padding-left: 10px;
        margin-left: 10px;
    }
}

.MapKey_keyColor__1sRFu {
    display: block;
    width: 18px;
    height: 5px;
    border-radius: 2.5px;
    margin-right: 10px;
}

.MapKey_button__2jgg8 {
    background: #009EBD;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 30px;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: 'bebas_neue', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    text-transform: uppercase;
    outline: none;
    pointer-events: all;
}

.MapKey_buttonIcon__3awCo {
    position: relative;
    width: 8px;
    height: 2px;
    margin-left: 5px;
}

.MapKey_buttonIcon__3awCo::before,
.MapKey_buttonIcon__3awCo::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: opacity .15s linear, -webkit-transform .15s linear;
    transition: transform .15s linear, opacity .15s linear;
    transition: transform .15s linear, opacity .15s linear, -webkit-transform .15s linear;
}

.MapKey_buttonIcon__3awCo::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
            transform: translateY(-50%) rotate(90deg);
}

.MapKey_button__2jgg8[aria-expanded="true"] .MapKey_buttonIcon__3awCo::after  {
    -webkit-transform: translateY(-50%) rotate(0);
            transform: translateY(-50%) rotate(0);
}
.MapControls_root__3iInk {
    position: absolute;
    right: 25px;
    bottom: 15px;
    z-index: 1;
    width: 50px;
}

.MapControls_control__2Cm5K {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    transition: all 300ms ease-in-out;
    margin-bottom: 20px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    color: #fff;
    border: 5px solid transparent;
}

.MapControls_control__2Cm5K:focus {
    outline: none;
}

.MapControls_controlIcon__1jZYg {
    font-size: 24px;
    pointer-events: none;
}

.MapControls_controlInfo__k0y8L {
    visibility: hidden;
    margin-top: -70px;
    opacity: 0;
    background: #009EBD;
}

.MapControls_controlInfoActive__2ytFn {
    visibility: visible;
    margin-top: 0;
    opacity: 1;
}

.MapControls_controlSettings__2vORm {
    background: #B72055;
}

.MapControls_controlDraw__3_4fT {
    background: #4897E8;
}

.MapControls_controlDrawActive__EB9l5 {
    background: #4897E8;
    border: 5px solid #9CCDFF;
    height: 60px;
    width: 60px;
    margin-top: -5px;
    margin-left: -5px;
}

.MapControls_controlTrash__3LmGO {
    background: #B72055;
}


@media (min-width: 1024px) {
    .MapControls_root__3iInk {
        bottom: auto;
        top: 50px;
        right: 50px;
    }
}
.Main_root__1fwak {
    position: relative;
    height: 100%;
    overflow: auto;
}

@media (max-width: 1023px) {
    .Main_root__1fwak::before {
        content: '';
        display: block;
        height: calc(100% - 100px);
    }
}

.Main_body__1IIqX {
    position: fixed;
    top: 120px;
    left: 0;
    height: calc(100% - 120px);
    width: 100%;
    transition: all 300ms ease-out;
}

.Main_isMini__F4HIR {
    height: calc(100% - 100px - 120px);
}

.Main_isDrawMode__H8MRR .mapboxgl-canvas-container {
    cursor: crosshair;
}

.Main_isDrawMode__H8MRR .mouse-pointer .mapboxgl-canvas-container {
    cursor: pointer;
}

@media (min-width: 1024px) {
    .Main_body__1IIqX {
        position: static;
        height: 100%;
    }
}


.Main_logo__1inKx {
    position: absolute;
    left: 50px;
    top: 40px;
    width: 96px;
    display: none;
    z-index: 1;
    cursor: pointer;
}

@media (min-width: 1024px) {
    .Main_logo__1inKx {
        display: block;
    }    
}
.App_root__1adr5 {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  overflow-x: hidden;
}

.App_container__3TyOr {
  height: 100%;
}


.App_header__Q_N2b {
  text-align: center;
}

.App_headerTitle__dccIs {
  font-size: 28px;
  margin-bottom: 10px;
}

.App_headerDesc__bbn8F {
  font-size: 12px;
  margin-bottom: 2em;
}

.App_tabs__CxXk6 {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0 -20px 20px;
  padding: 0 5px;
  border-bottom: 2px solid #024D6C;
}

.App_tab__3UzLr {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: 0 5px;
}

.App_tabLink__1MWwb {
  display: block;
  padding: 10px 15px;
  background: #00212F;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #fff;
  font-family: 'bebas_neue', sans-serif;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  color: #225870;
  transition: all 0.2s linear;
  position: relative;
  overflow: hidden;
}

.App_tabLink__1MWwb[aria-current] {
  background: #003E59;
  color: #fff;
}

.App_tabIcon__P6upr {
  display: block;
  font-size: 38px;
  margin: 0 auto 10px;
  color: #009EBD;
}

.App_tabLink__1MWwb[aria-current] .App_tabIcon__P6upr {
  color: #4ED2BB;
}

.App_tabBadge__13aO1 {
  background: #85719E;
  color: #fff;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  -webkit-transform: rotate(-45deg) translate(-50%, 15px);
          transform: rotate(-45deg) translate(-50%, 15px);
  width: 100%;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  padding: 2px 0;
}

.AddToHomescreen_overlay__1Hhar {
    position: absolute;
    z-index: 9999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.7) 70%);
}

.AddToHomescreen_dialog__2q5lX {
    position: absolute;
    color: #fff;
    position: absolute;
    bottom: 40px;
    left: 20px;
    right: 20px;
    background: #009EBD;
    font-size: 16px;
    padding: 10px 15px 10px 20px;
    border-radius: 3px;
    font-weight: 500;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.AddToHomescreen_btn__2lldb {
    color: #fff;
    font-size: 18px;
}

.AddToHomescreen_indicator__16oXM {
    position: absolute;
    width: 28px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    color: #fff;
    font-size: 28px;
}


/* Safari */

.AddToHomescreen_isSafariMobile__1Tj6c .AddToHomescreen_indicator__16oXM {
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}


.AddToHomescreen_isSafariTablet__3G1Gm {
    background-image: linear-gradient(to top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.7) 70%);
}

.AddToHomescreen_isSafariTablet__3G1Gm .AddToHomescreen_dialog__2q5lX {
    top: 40px;
    bottom: auto;
}

.AddToHomescreen_isSafariTablet__3G1Gm .AddToHomescreen_indicator__16oXM {
    top: 0;
    right: 120px;
    -webkit-transform: translateX(-50%) rotate(180deg);
            transform: translateX(-50%) rotate(180deg);
}

@media (max-width: 1023px) {
    .AddToHomescreen_isSafariTablet__3G1Gm .AddToHomescreen_indicator__16oXM {
        top: 0;
        right: 105px;
    }
}

/* Chrome */

.AddToHomescreen_isChromeMobile__3uBPd {
    background-image: linear-gradient(to top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.7) 70%);
}

.AddToHomescreen_isChromeMobile__3uBPd .AddToHomescreen_dialog__2q5lX {
    top: 40px;
    bottom: auto;
}

.AddToHomescreen_isChromeMobile__3uBPd .AddToHomescreen_indicator__16oXM {
    top: 0;
    right: 2px;
    -webkit-transform: translateX(-50%) rotate(180deg);
            transform: translateX(-50%) rotate(180deg);
}
/* Font definitions */

@font-face {
  font-family: 'bebas_neue';
  src: url(/static/media/bebasneue_bold-webfont.cc90cb47.woff2) format('woff2'),
       url(/static/media/bebasneue_bold-webfont.56373867.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url(/static/media/bebasneue_book-webfont.5d642a0e.woff2) format('woff2'),
       url(/static/media/bebasneue_book-webfont.044f45dc.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url(/static/media/bebasneue_light-webfont.ebf4284d.woff2) format('woff2'),
       url(/static/media/bebasneue_light-webfont.490917bc.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url(/static/media/bebasneue_regular-webfont.8ee42c00.woff2) format('woff2'),
       url(/static/media/bebasneue_regular-webfont.ce07b651.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url(/static/media/bebasneue_thin-webfont.c7b243f2.woff2) format('woff2'),
       url(/static/media/bebasneue_thin-webfont.51e7a13f.woff) format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'quicksand';
  src: url(/static/media/quicksand-bold-webfont.dd495810.woff2) format('woff2'),
       url(/static/media/quicksand-bold-webfont.460c288e.woff) format('woff');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'quicksand';
  src: url(/static/media/quicksand-light-webfont.fc106948.woff2) format('woff2'),
       url(/static/media/quicksand-light-webfont.8367b48e.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'quicksand';
  src: url(/static/media/quicksand-medium-webfont.cacab592.woff2) format('woff2'),
       url(/static/media/quicksand-medium-webfont.d435a998.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'quicksand';
  src: url(/static/media/quicksand-regular-webfont.82a20369.woff2) format('woff2'),
       url(/static/media/quicksand-regular-webfont.819d2b7b.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}


/* Base elements */

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'quicksand', sans-serif;
  line-height: 1.25;
  overflow: hidden;
  font-size: 100%;
  color: #4A4A49;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4 {
  font-family: 'bebas_neue', sans-serif;
  font-weight: bold;
  line-height: .95;
  margin: 0 0 1em;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

p, ul, ol {
  margin: 0 0 1em;
}

button {
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, 
              -webkit-transform 150ms ease;
  transition: background 250ms ease-in-out, 
              transform 150ms ease;
  transition: background 250ms ease-in-out, 
              transform 150ms ease, 
              -webkit-transform 150ms ease;
  color: inherit;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
}

a {
  color: #B72055;
}

/* Fade in */

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: all 300ms ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-out;
}

/* Push in top */

.pushInTop-enter {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.pushInTop-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: all 300ms ease-out;
}
.pushInTop-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.pushInTop-exit-active {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  transition: all 300ms ease-out;
}

/* Right */

.slideInRight-enter {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.slideInRight-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: all 300ms ease-out;
}
.slideInRight-exit {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.slideInRight-exit-active {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: all 300ms ease-out;
}

/* Bottom */

.slideInBottom-enter {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.slideInBottom-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: all 300ms ease-out;
}
.slideInBottom-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.slideInBottom-exit-active {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: all 300ms ease-out;
}
