.geocoder {
    position: relative;
}


.input {
    width: 100%;
    height: 25px;
    background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    appearance: none;
    font-size: 16px;
    font-family: inherit;
    color: #fff;
    padding: 0;
}

.input:focus {
    outline: none;
}

.input::placeholder {
    color: rgba(255, 255, 255, .5);
}


.inputLocate {
    composes: input;
    padding-right: 30px;
}


.locateBtn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    color: #009EBD;
}


.autocomplete {
    position: absolute;
    top: 100%;
    z-index: 999;
    background: #003E59;
    color: #fff;
    list-style: none;
    padding: 0;
    box-shadow: 0 3px 14px 0 rgba(0,0,0,0.1);
}

.autocompleteItem {
    text-align: left;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s linear;
}

.autocompleteItem:hover {
    background: rgba(255,255,255,0.1);
}

.autocompleteItem[data-selected="true"] {
    background: #009EBD;
}