.plans {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0 -20px 20px;
    padding: 0 5px;
    border-bottom: 2px solid #024D6C;
}

.plan {
    position: relative;
    flex: 1 1 auto;
    margin: 0 5px;
}

.planRadio {
    position: absolute;
    opacity: 0;
}

.planLabel {
    display: block;
    padding: 10px 15px;
    background: #00212F;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #fff;
    font-family: 'bebas_neue', sans-serif;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    color: #225870;
    transition: all 0.2s linear;
}

.planRadio:checked + .planLabel {
    background: #003E59;
    color: #fff;
}

.timeDistance {
    font-size: 18px;
    color: #009EBD;
}

.timeDistance time {
    color: #FFFFFF;
    margin-right: 5px;
}

.timeDistance strong {
    font-family: 'bebas_neue', sans-serif;
    font-size: 28px;
    font-weight: 700;
}


/* Accordion */


@keyframes fadein {
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
}
  

.accordionItem:last-child {
    border-bottom: 1px solid rgba(255,255,255,0.1);
}

.accordionButton {
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(255,255,255,0.1);
    padding: 15px 0;
    cursor: pointer;
    font-family: 'bebas_neue', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #009EBD;
}


.accordionToggle {
    position: relative;
    font-family: 'quicksand', sans-serif;
    font-size: 14px;
    color: #225870;
    letter-spacing: 0.5px;
    text-align: right;
    padding-right: 15px;
}

.accordionToggle::before,
.accordionToggle::after {
    content: '';
    display: block;
    width: 8px;
    height: 2px;
    background: #009EBD;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .15s linear, opacity .15s linear;
}

.accordionToggle::after {
    transform: translateY(-50%) rotate(90deg);
}

.accordionToggleExpanded {
    composes: accordionToggle;
}

.accordionToggleExpanded::after {
    transform: translateY(-50%) rotate(0);
}

.accordionPanel {
    animation: fadein 0.35s ease-in;
    user-select: none;
    padding: 0 0 20px;
}


/* Directions */


.directions {
    list-style: none;
    padding: 0;
}

.direction {
    display: flex;
    padding: 13px 12px;
    font-size: 14px;
}

.direction:nth-child(odd) {
    background: rgba(34,88,112,.25);
}

.direction:last-child .directionIcon {
    color: #B72055;
}

.directionIcon {
    font-size: 18px;
    margin-right: 10px;
}

.directionBody small {
    font-size: 12px;
    color: rgba(255, 255, 255, .5);
}

.directionBody small::before {
    content: '';
    display: inline-block;
    height: 5px;
    width: 18px;
    border-radius: 2.5px;
    background: #4897e8;
    margin-right: 10px;
    vertical-align: middle;
}


.directionBody small[data-provision="Footpath"]::before,
.directionBody small[data-provision="Bridleway"]::before,
.directionBody small[data-provision="Track"]::before,
.directionBody small[data-provision="Steps"]::before,
.directionBody small[data-provision="Pedestrianized area"]::before {
    background: #efc82f;
}

.directionBody small[data-provision="Cycle path"]::before,
.directionBody small[data-provision="Path"]::before {
    background: #2A3BBD;
}