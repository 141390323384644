/* Splash screen */

.splash {
  height: 100%;
  overflow: auto;
  background: #003e59 0 100% / contain no-repeat;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: contain;
}

@media (min-width: 1024px) {
  .splash {
    background-size: contain;
  }
}

.body {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 375px;
  margin: 0 auto;
  padding: 40px 20px;
}

.logo {
  width: 148px;
  margin: 0 0 20px;
}

@media (min-width: 1024px) {
  .logo {
    width: 264px;
  }
}

.title {
  font-size: 51px;
  margin-bottom: 0.25em;
  max-width: 100%;
}

.desc {
  margin-bottom: 0;
  max-width: 100%;
}

.btn {
  composes: button from "../../shared.css";
  composes: buttonBlock from "../../shared.css";
  margin: 1em 0 0;
  text-decoration: none;
}

.copy {
  font-size: 12px;
  opacity: 0.3;
  margin-top: auto;
  padding-bottom: 40px;
}

.newFeatures {
  border-top: 2px solid #024D6C;
  padding-top: 20px;
  margin-top: 20px;
}

.bannerHeading {
  margin: 0 0 .5em;
}

.featureHeading {
  text-transform: none;
  font-family: inherit;
  margin: 0 0 .5em;
}