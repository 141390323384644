.catHeader {
    color: #009EBD;
    font-size: 24px;
}

@media (min-width: 1024px) {
    .catHeader {
        font-size: 20px;
    }
}


.list {
    list-style: none;
    padding: 0;
    margin: 0 -20px 25px;
}

@media (min-width: 1024px) {
    .list {
        margin-bottom: 40px;
    }
}