.container {
    display: flex;
    align-items: flex-start;
    flex: 1 1 auto;
}

@media (min-width: 1024px) {
    .container {
        margin-bottom: 30px;
    }
}

.form {
    flex: 1 1 auto;
}

.fields {
    position: relative;
    padding-left: 30px;
}

.backBtn {
    font-size: 16px;
    margin: 5px 10px 0 0;
}

.graphic {
    position: absolute;
    left: 0;
    top: .35em;
}

.label {
    font-size: 13px;
    color: #009EBD;
    line-height: 1;
}

.button {
    background: #B72055;
    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.20);
    border-radius: 3px;
    width: 100%;
    height: 50px;
    font-size: 17px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 500;
    margin: 20px 0 0;
}