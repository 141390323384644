.button {
    background: #B72055;
    color: #fff;
    font-weight: 500;
    border: 0;
    font-size: 17px;
    padding: 15px 30px;
    box-shadow: 0 3px 7px rgba(0,0,0,0.2);
    border-radius: 3px;
}

.buttonBlock {
    width: 100%;
}


/* Custom scrollbar */

.customScrollbar {
    height: 100%;
    overflow: auto;
    padding: 0 30px;
}

.customScrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: 0 0;
}

.customScrollbar::-webkit-scrollbar:hover {
    background: 0 0;
}

.customScrollbar::-webkit-scrollbar-track {
    background: 0 0;
}

.customScrollbar::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.25);
    border-color: transparent;
    width: 6px;
    border-radius: 3px
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,.35)
}

.customScrollbar:-webkit-scrollbar-track:hover {
    background: 0 0
}


/* Custom scrollbar dark */

.customScrollbarDark {
    composes: customScrollbar;
}

.customScrollbarDark::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,.3)
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(255,255,255,.4)
}


/* Custom scrollbar light */

