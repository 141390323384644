.table {
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

.row:nth-of-type(odd) {
    background: rgba(34, 88, 112, .25);
}

.row th,
.row td {
    padding: 10px 5px;
}

.row th {
    font-weight: normal;
}

.row td {
    font-weight: bold;
    text-align: right;
}

.small {
    font-size: 12px;
    color: rgba(255,255,255,.65);
    font-weight: 500;
    margin: 0;
}