.map {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

/* Map styling */

:global(.mapboxgl-canvas) {
    outline: none;
}


:global(.mapboxgl-popup-content) {
    width: 200px;
    background: #003E59;
    color: #fff;
    padding: 10px;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25);
}

:global(.mapboxgl-popup-content h3) {
    color: #fff;
    font-family: 'quicksand';
    font-weight: 500;
    margin: 0;
    font-size: 12px;
}

:global(.mapboxgl-popup-content small) {
    color: #fff;
    font-family: 'quicksand';
    font-weight: 500;
    opacity: 0.5;
    font-size: 12px;
}

:global(.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip) {
    border-top-color: #003E59;
}

:global(.mapboxgl-popup-anchor-top .mapboxgl-popup-tip) {
    border-bottom-color: #003E59;
}

:global(.mapboxgl-popup-anchor-left .mapboxgl-popup-tip) {
    border-right-color: #003E59;
}

:global(.mapboxgl-popup-anchor-right .mapboxgl-popup-tip) {
    border-left-color: #003E59;
}

:global(.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip) {
    border-top-color: #003E59;
}

:global(.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip) {
    border-top-color: #003E59;
}

:global(.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip) {
    border-bottom-color: #003E59;
}

:global(.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip) {
    border-bottom-color: #003E59;
}