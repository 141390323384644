.panel {
    background: #fff;
    display: flex;
    flex-direction: column;
}

.panelPositioned {
    position: fixed;
    z-index: 3;
    overflow: auto;
}

.right {
    composes: panel;
    composes: panelPositioned;
    right: 0;
    top: 0;
    width: 320px;
    height: 100%;
}

.bottom {
    composes: panel;
    composes: panelPositioned;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc((100% - 130px) / 2);
}

.static {
    composes: panel;
    position: relative;
    z-index: 2;
}

.dark {
    background: #002B3E;
    color: #fff;
}

.bottom .titleBar,
.static .titleBar {
    height: auto;
}

.titleBar {
    display: flex;
    padding: 20px 25px 15px 0;
    margin-left: 25px;
    align-items: flex-end;
    height: 90px;
    border-bottom: 1px solid #EFEFEF;
}

.title {
    margin: 0;
    color: #009EBD;
}

.intro {
    font-size: 14px;
    margin: 0 0 30px;
    font-weight: bold;
}

.closeBtn {
    margin: 0 0 -4px auto;
    font-size: 20px;
    color: #B4B4B3;
}

.body {
    padding: 15px 20px;
    flex: 1;
    composes: customScrollbar from '../../../shared.css';
}

@media (min-width: 1024px) {
    .body {
        padding: 15px 25px;
    }
}