/* Font definitions */

@font-face {
  font-family: 'bebas_neue';
  src: url('assets/fonts/bebasneue_bold-webfont.woff2') format('woff2'),
       url('assets/fonts/bebasneue_bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url('assets/fonts/bebasneue_book-webfont.woff2') format('woff2'),
       url('assets/fonts/bebasneue_book-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url('assets/fonts/bebasneue_light-webfont.woff2') format('woff2'),
       url('assets/fonts/bebasneue_light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url('assets/fonts/bebasneue_regular-webfont.woff2') format('woff2'),
       url('assets/fonts/bebasneue_regular-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url('assets/fonts/bebasneue_thin-webfont.woff2') format('woff2'),
       url('assets/fonts/bebasneue_thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'quicksand';
  src: url('assets/fonts/quicksand-bold-webfont.woff2') format('woff2'),
       url('assets/fonts/quicksand-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'quicksand';
  src: url('assets/fonts/quicksand-light-webfont.woff2') format('woff2'),
       url('assets/fonts/quicksand-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'quicksand';
  src: url('assets/fonts/quicksand-medium-webfont.woff2') format('woff2'),
       url('assets/fonts/quicksand-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'quicksand';
  src: url('assets/fonts/quicksand-regular-webfont.woff2') format('woff2'),
       url('assets/fonts/quicksand-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


/* Base elements */

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'quicksand', sans-serif;
  line-height: 1.25;
  overflow: hidden;
  font-size: 100%;
  color: #4A4A49;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4 {
  font-family: 'bebas_neue', sans-serif;
  font-weight: bold;
  line-height: .95;
  margin: 0 0 1em;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

p, ul, ol {
  margin: 0 0 1em;
}

button {
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, 
              transform 150ms ease;
  color: inherit;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
}

a {
  color: #B72055;
}

/* Fade in */

:global(.fade-enter) {
  opacity: 0.01;
}
:global(.fade-enter-active) {
  opacity: 1;
  transition: all 300ms ease-out;
}
:global(.fade-exit) {
  opacity: 1;
}
:global(.fade-exit-active) {
  opacity: 0.01;
  transition: all 300ms ease-out;
}

/* Push in top */

:global(.pushInTop-enter) {
  transform: translateY(-100%);
}
:global(.pushInTop-enter-active) {
  transform: translateY(0);
  transition: all 300ms ease-out;
}
:global(.pushInTop-exit) {
  transform: translateY(0);
}
:global(.pushInTop-exit-active) {
  transform: translateY(-100%);
  transition: all 300ms ease-out;
}

/* Right */

:global(.slideInRight-enter) {
  transform: translateX(100%);
}
:global(.slideInRight-enter-active) {
  transform: translateX(0);
  transition: all 300ms ease-out;
}
:global(.slideInRight-exit) {
  transform: translateX(0);
}
:global(.slideInRight-exit-active) {
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

/* Bottom */

:global(.slideInBottom-enter) {
  transform: translateY(100%);
}
:global(.slideInBottom-enter-active) {
  transform: translateY(0);
  transition: all 300ms ease-out;
}
:global(.slideInBottom-exit) {
  transform: translateY(0);
}
:global(.slideInBottom-exit-active) {
  transform: translateY(100%);
  transition: all 300ms ease-out;
}