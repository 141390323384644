.list {
    list-style: none;
    padding: 0;
    margin-top: -15px;
}

.listItem {
    border-bottom: 1px solid #EFEFEF;
    margin-right: -25px;
    padding-right: 25px;
}

.toggle {
    display: flex;
    width: 100%;
    padding: 10px 0;
    font-family: inherit;
    align-items: flex-start;
}

.toggle:focus {
    outline: none;
}

.toggleSwitch {
    display: block;
    width: 36px;
    height: 20px;
    background: #EFEFEF;
    position: relative;
    border-radius: 10px;
    margin-right: 15px;
    flex: none;
}

.toggleSwitch::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 8px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all 0.15s linear;
}

.toggleSwitchActive {
    composes: toggleSwitch;
}

.toggleSwitchActive::before {
    background: #B72055;
    transform: translateX(100%);
}

.toggleSwitchDisabled {
    composes: toggleSwitch;
    visibility: hidden;
}

.toggleLabel {
    margin: 0 20px 0 0;
    font-size: 14px;
    text-align: left;
}

.toggleLabelLg {
    composes: toggleLabel;
    font-size: 16px;
    margin-top: -0.15em;
}

.toggleIcon {
    margin: 0 0 0 auto;
    width: 20px;
}

.description {
    font-size: 12px;
    padding-left: 51px;
    padding-right: 40px;
}

.title {
    padding: 0 0 15px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #EFEFEF;
    color: #009EBD;
}