
.overlay {
    position: absolute;
    z-index: 9999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.7) 70%);
}

.dialog {
    position: absolute;
    color: #fff;
    position: absolute;
    bottom: 40px;
    left: 20px;
    right: 20px;
    background: #009EBD;
    font-size: 16px;
    padding: 10px 15px 10px 20px;
    border-radius: 3px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn {
    color: #fff;
    font-size: 18px;
}

.indicator {
    position: absolute;
    width: 28px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
    color: #fff;
    font-size: 28px;
}


/* Safari */

.isSafariMobile .indicator {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}


.isSafariTablet {
    background-image: linear-gradient(to top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.7) 70%);
}

.isSafariTablet .dialog {
    top: 40px;
    bottom: auto;
}

.isSafariTablet .indicator {
    top: 0;
    right: 120px;
    transform: translateX(-50%) rotate(180deg);
}

@media (max-width: 1023px) {
    .isSafariTablet .indicator {
        top: 0;
        right: 105px;
    }
}

/* Chrome */

.isChromeMobile {
    background-image: linear-gradient(to top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.7) 70%);
}

.isChromeMobile .dialog {
    top: 40px;
    bottom: auto;
}

.isChromeMobile .indicator {
    top: 0;
    right: 2px;
    transform: translateX(-50%) rotate(180deg);
}